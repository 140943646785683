import React from "react";
import "./Footer.css";
import {
  FaTelegramPlane,
  FaRegEnvelope,
  FaTwitter,
  FaMediumM,
  FaInstagram,
  FaRedditAlien,
  FaFacebookF,
  FaYoutube,
} from "react-icons/fa";
import twitter from "../../Assets/images/icon.png";
const Footer = () => {
  return (
    <footer>
      <div className="socialIcons">
        <ul>
          <li>
            {/* ....... */}
            <a
              href="https://t.me/DefiWorldFinance"
              rel="noreferrer"
              title="Telegram"
              target="_blank"
            >
              <FaTelegramPlane />
            </a>
          </li>
          <li>
            {/* ....... */}
            <a
              href="https://twitter.com/DefiWorldFin"
              rel="noreferrer"
              title="Telegram"
              target="_blank"
            >
              <img src={twitter} width={18} height={18} alt="twitter icon" />
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@DefiWorldFinance"
              rel="noreferrer"
              title="Medium"
              target="_blank"
            >
              <FaMediumM />
            </a>
          </li>
          <li>
            {/* ....... */}
            <a
              href="https://www.instagram.com/DefiWorldFinance/
"
              rel="noreferrer"
              title="Instagram"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a
              href="https://www.reddit.com/user/DefiWorldFinance/"
              rel="noreferrer"
              title="Reddit"
              target="_blank"
            >
              <FaRedditAlien />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/DefiWorldFinance"
              rel="noreferrer"
              title="Facebook"
              target="_blank"
            >
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@DefiWorldFinance"
              rel="noreferrer"
              title="Youtube"
              target="_blank"
            >
              <FaYoutube />
            </a>
          </li>
        </ul>
      </div>
      <div className="copyright">
        <p>© DeFiWorld All Rights Reserved</p>
      </div>
    </footer>
  );
};
export default Footer;