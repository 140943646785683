import React from 'react'
import './Aboutsec.css'
import logIcon from '../../Assets/images/logo.gif'
// import cube from '../../Assets/images/cube.gif'

const Aboutsec = () => {
    return (
        <>
            <section className="aboutsec" id="aboutusSrl">
                <div className="container">
                    <div className='titlebox blinkers'>
                        <h6>About us</h6>
                        <h2>The DefiWorld</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12 mb-5">
                            <div className="imageBox">
                                <img src={logIcon} alt="logIcon" className='defiWorldIcon ui2' />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="infobox">
                                <h2>Introducing DEFI World's Innovative Solution</h2>
                                <p className="">At DEFI World, we boast a team of seasoned developers and blockchain experts, fully committed to shaping an inclusive and decentralized digital future where individuals retain complete control over their digital assets and identities.</p>
                                <p className="mb-5">We are excited to introduce our innovative NFT staking platform on the Polygon Chain, which presents users with a unique opportunity to earn rewards while maintaining the utmost security and decentralization. In this presentation, we will delve into the advantages of NFT staking on Polygon and elucidate the inner workings of our platform.</p>

                                <div className='aboutbtn d-coloumn gap-0 gap-sm-5 d-sm-flex'>
                                    <div className="buttonGrp headGrpBtn abtbtn1">
                                        <a href="https://docs.defiworld.finance/" target="_blank" rel="noreferrer" className="defi_btn">Whitepaper</a>
                                    </div>
                                    <div className="buttonGrp headGrpBtn abtbtn2">
                                        <a href="https://skynet.certik.com/projects/defi-world" target="_blank" rel="noreferrer" className="defi_btn">Certified</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Aboutsec