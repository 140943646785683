import React from 'react'
import './Faqs.css'
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "01 : What is DEFI World?",
            content: `DEFI World is a cutting-edge platform for NFT Staking, operating on the Polygon Chain. As a technology company, we harness the power of blockchain technology to develop advanced decentralized applications (dApps). Our primary mission revolves around crafting innovative solutions applicable across various industries and use cases. DEFI World is dedicated to exploring the full potential of blockchain technology and delivering products that bring substantial value to our users.`,
        },
        {
            title: "02 : Which Wallet Should I Utilize?",
            content: `DEFI World seamlessly integrates with all cryptocurrency wallets. We recommend the following options: 
            <p>For Mobile Devices (Smartphones, Tablets): Metamask Wallet app is a great choice. For iPhone users, you can [download it here](https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202). For Android users, please <a href="https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US&pli=1" target="_blank">[click here]</a>.</p>
            <p>For Desktops/Laptops: You can access it <a href="https://metamask.io/download/" target="_blank">[here]</a></p>`,
        },
        {
            title: "03 : How Can I Purchase/Sell Polygon If I'm New to Cryptocurrencies?",
            content: `If you're new to cryptocurrencies, there are numerous user-friendly methods for buying and selling digital currencies with regular fiat money. These methods are designed for ease of use. Your initial transaction, converting fiat money to digital currency, should take no longer than five minutes. We recommend using a trusted cryptocurrency exchange aggregator to facilitate this process.`,
        },
        {
            title: "04 : How Can I Register on DEFI World?",
            content: `To register on DEFI World, you must first acquire DefiWorld Tokens and DefiWorld NFTs.
            `,
        },
        {
            title: "05 : Can I Register Without a Referral Link?",
            content: `No, the registration process requires you to enter a referral code before your account can be successfully created.`,
        },
        {
            title: "06 :How do I choose the best staking pool?",
            content: `Evaluate based on your investment goals and the pool's returns.`,
        },
        {
            title: "07 :Are there multiple pools for staking DWC?",
            content: ` Yes, various pools are available for different staking durations.`,
        },
        {
            title: "08 :Will I Get referral on referring a user to buy DWC in ICO sale?",
            content: ` You will receive a referral when a user uses your referral link & buys DWC in any of the 3 sale round.`,
        },
        {
            title: "09 :How many public NFTs are available for users to stake?",
            content: ` There are a total of 3,500 public NFTs available for users to stake on the platform. This provides a wide variety of options for users interested in participating in NFT staking.`,
        },
        {
            title: "10 : What swapping pairs are available on DEFI World?",
            content: ` Currently, DEFI World supports the swapping pair of DWC to USDT. This pairing allows users to trade between DWC and a stablecoin, offering a convenient way to manage assets within the platform.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#50dbff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#50dbff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    return (
        <section className="faqs" id='faqsSrl'>
            <div className="container">
                <div className='titlebox'>
                    <h6>FAQ's</h6>
                    <h2>Quick Questions</h2>
                    {/* <p>Here are Some Important things you should know!.</p> */}
                </div>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs