import React from 'react'
import './Roadmap.css'
import circleFrame from '../../Assets/images/circleFrame.gif'
const Roadmap = () => {
    return (
        <section className="roadmap" id='roadmapSrl'>
            <div className="container">
                <div className='titlebox'>
                    <h6>DeFiWorld Roadmap</h6>
                    <h2>Futuristic Roadmap</h2>
                    {/* <p>Our Roadmap for 2023</p> */}
                </div>
                <div className="buttonMainbanner">
                    <div className="row flex-wrap align-items-center justify-content-center">
                       
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>Q3 2023</span>
                            </div>
                            <p>Market Research & Conceptualization</p>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>Q4 2023</span>
                            </div>
                            <p>Development Of Core Features</p>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>Q1 2024</span>
                            </div>
                            <p>NFT & ICO Launch & Platform Rollout</p>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>Q2 2024</span>
                            </div>
                            <p>Expansion into Decentralized Gaming</p>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>Q3 2024</span>
                            </div>
                            <p>Enhancing Platform Capabilities & User Experience</p>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>Q4 2024</span>
                            </div>
                            <p>Integration with Other Blockchains and Services</p>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>Q1 2025</span>
                            </div>
                            <p>Community-Driven Development and Governance</p>

                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4'>
                            <div className='roadInfobx'>
                                <img src={circleFrame} alt="roadmap" />
                                <span>BEYOND 2025</span>
                            </div>
                            <p>Coutinuous Innovation & Growth</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Roadmap