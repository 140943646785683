import React from 'react'
import banner1 from '../../Assets/images/American.png'
import banner2 from '../../Assets/images/Blackjack.png'
import banner3 from '../../Assets/images/crash.png'
import banner4 from '../../Assets/images/dice.png'
import banner5 from '../../Assets/images/horseracing.png'

const Comingsoonpage = () => {
  return (
    <section  className='comingsoon' id='comingsoonSrl' >
<div className='titlebox'>
                        <h6>Games</h6>
                        <h2>WEB3 Games</h2>
                    </div>

        <div className="carditems">
      
        <div className="card">
        <div className="gamesimg">
        <img src={banner1} alt="" />

                </div>
                <div className="gamesbtn headGrpBtn  ">
                                    <a href="https://game.defiworld.finance/" target="_blank" rel="noreferrer" className="defi_btn americantext">American Roulette</a>
                                </div>
            <div>
                
            </div>
        </div>
        <div className="card">
         <div className="gamesimg">
            <img src={banner2} alt="" />
                  
                </div> 
                <div className="gamesbtn headGrpBtn ">
                                    <a href="https://game.defiworld.finance/" target="_blank" rel="noreferrer" className="defi_btn">Blackjack</a>
                                </div>
        </div>
        <div className="card">
        <div className="gamesimg">
        <img src={banner3} alt="" />
                </div>
                <div className="gamesbtn headGrpBtn ">
                                    <a href="https://game.defiworld.finance/" target="_blank" rel="noreferrer" className="defi_btn">Crash</a>
                                </div>
         
        </div>
        <div className="card">
        <div className="gamesimg">
        <img src={banner4} alt="" />

                </div>
                <div className="gamesbtn headGrpBtn ">
                                    <a href="https://game.defiworld.finance/" target="_blank" rel="noreferrer" className="defi_btn">Dice</a>
                                </div>
        </div>
        <div className="card">
              {/* <div className="lablesection">
                    <div className="label">
                        <span> Coming soon </span>
                    </div>
                </div> */}
        <div className="gamesimg">
        <img src={banner5} alt="" />

                </div>
                <div className="gamesbtn headGrpBtn ">
                                    <a href="https://game.defiworld.finance/" target="_blank" rel="noreferrer" className="defi_btn">Horse Racing</a>
                                </div>
        </div>
        </div>


    </section>
    
  )
}

export default Comingsoonpage