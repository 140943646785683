import React, { useEffect } from "react";
import { useState } from "react";
// import { NavLink } from 'react-router-dom'
import logo from '../../Assets/images/logo.gif'
import logoname from '../../Assets/images/logo.gif'
import logoAnim from "../../Assets/images/logo.gif";
import "./header.css";
import { Link } from "react-scroll";

// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import toast, { Toaster } from 'react-hot-toast';

// const notify = () => toast.success('Copied Successfully!')
const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showHamburger, setHamburger] = useState(false);
  const [DefiPrice, setDefiPrice] = useState();

  const [dwcPrice,setDwcPrice] = useState();

  function Price() {
    try {
      const data = fetch("https://oc-price-api.onrender.com/oc-price")
        .then((res) => res.json())
        .then((json) => {
          setDefiPrice((Math.floor(json.price * 100) / 100).toFixed(2));
        });
    } catch (error) {
      console.log(error);
    }
  }

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    setHamburger(!showHamburger);
  };

  useEffect(() => {
    Price();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      Price();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(()=>{

    const fetchPrice = async()=>{

      const data = await fetch('https://defi-price-node.vercel.app/get_SwapRate')
      .then(async(res)=>{
        if (res.ok) {
          const jsonResponse = await res.json();
          return  jsonResponse.data[0]/10000;
        } else {
          console.log('Error: ', data.status);
        }
      })

setDwcPrice(data);

}

    fetchPrice();

  },[])

  return (
    <>
      <header className={`${showNavbar && "openmenu"}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-2 text-start">
              <div className="logo">
                                <img src={logo} alt="logo" width="140" height="140" className='d-none d-md-block' />
                                <img src={logoname} alt="logo" width="275" height="120" className='d-block d-md-none' />
                            </div>
            </div>
            <div
              className={`col-8 text-center d-none d-md-block position-relative`}
            >
              <ul className="menu list-unstyled m-0 p-0">
                <li>
                  <Link to="aboutusSrl" smooth={true} duration={0}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="advantagesSrl" smooth={true} duration={0}>
                    Advantages
                  </Link>
                </li>
                {/* <li>
                                    <Link to="howdefiSrl" smooth={true} duration={0}>How DEFI</Link>
                                </li> */}
                <li>
                  <Link to="tokenomicsSrl" smooth={true} duration={0}>
                    Tokenomics
                  </Link>
                </li>
                <li>
                  <Link to="roadmapSrl" smooth={true} duration={0}>
                    Roadmap
                  </Link>
                </li>
                <li>
                  <Link to="faqsSrl" smooth={true} duration={0}>
                    Faqs
                  </Link>
                </li>
              </ul>
            </div>
            <div className="logoDesktop">
              {/* <img src={logo} alt="logo" width="140" height="140" /> */}
              {/* <h2>DefiWorld</h2> */}
              <div className="headertext">
                <ul class="first-word">
                  <li>D</li>
                  <li>e</li>
                  <li>f</li>
                  <li>i</li>
                </ul>
                <ul class="second-word">
                  <li>W</li>
                  <li>o</li>
                  <li>r</li>
                  <li>l</li>
                  <li>d</li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-2 text-end">
              <div className="rightboxmenu">
                <ul className="balancebox list-unstyled ">
                  <li>
                    Price<strong>{dwcPrice && `$${dwcPrice}`}</strong>
                  </li>
                  {/* <li onClick={notify}>
                                            <CopyToClipboard text="0x3ca3903bf9020e25a6b5141a4d9d6247f0c710ca">
                                                <span className='defi_btn'>0X3c....10ca</span>
                                            </CopyToClipboard>
                                        </li> */}
                </ul>
                <div
                  className={`hamburger d-md-none ${
                    showHamburger && "is-active"
                  }`}
                  onClick={handleShowNavbar}
                >
                  <span className="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="before">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      {/* Mobile Menu Start */}
      <div className={`menubar ${showNavbar && "mobileMenu"}`}>
        <ul className="menu list-unstyled m-0 p-0">
          <li>
            <Link
              to="aboutusSrl"
              smooth={true}
              duration={0}
              onClick={handleShowNavbar}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="advantagesSrl"
              smooth={true}
              duration={0}
              onClick={handleShowNavbar}
            >
              Advantages
            </Link>
          </li>
          {/* <li>
            <Link
              to="howdefiSrl"
              smooth={true}
              duration={0}
              onClick={handleShowNavbar}
            >
              How DEFI
            </Link>
          </li> */}
          <li>
            <Link
              to="tokenomicsSrl"
              smooth={true}
              duration={0}
              onClick={handleShowNavbar}
            >
              Tokenomics
            </Link>
          </li>
          <li>
            <Link
              to="roadmapSrl"
              smooth={true}
              duration={0}
              onClick={handleShowNavbar}
            >
              Roadmap
            </Link>
          </li>
          <li>
            <Link
              to="faqsSrl"
              smooth={true}
              duration={0}
              onClick={handleShowNavbar}
            >
              Faqs
            </Link>
          </li>
        </ul>
      </div>
      {/* Mobile Menu End */}
      {/* <Toaster
                position="bottom-right"
                reverseOrder={false}
            /> */}
    </>
  );
};

export default Header;
