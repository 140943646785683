import React from 'react'
import './Advantages.css'
import featureanim from '../../Assets/images/featureanim.gif'
import nftmain from '../../Assets/images/v5_final.mp4'
import ReactPlayer from 'react-player'
import LazyLoad from 'react-lazyload';
// import Lottie from 'react-lottie';
const Advantages = () => {
    return (
        <>
            <section className="advantages" id="advantagesSrl">
                <div className="container">
                    <div className='titlebox'>
                        <h6>Advantages</h6>
                        <h2>Advantages of NFT Staking</h2>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='leftinfoFaq'>
                                <p><span>01</span>Swift Transactions: Polygon serves as a Layer 2 scaling solution, offering rapid and cost-effective transactions, in stark contrast to Ethereum. This ensures that users can engage in NFT staking without concerns about exorbitant gas fees or sluggish transaction processing times.</p>
                                <p><span>02</span>Security and Decentralization: DEFI World's NFT staking platform is constructed on the robust foundation of Polygon, guaranteeing a secure and decentralized environment for staking activities. Users exercise complete authority over their assets, and the platform operates under the safeguard of smart contracts that facilitate equitable and transparent rewards distribution.</p>
                                <p><span>03</span>Diversification of Portfolios: NFT staking not only enhances the value of NFT holdings but also permits users to diversify their portfolios by earning rewards in the form of cryptocurrency or tokens. This supplementary passive income stream can act as a hedge against the volatility of the cryptocurrency market.</p>
                                {/* <div className="buttonGrp headGrpBtn">
                                    <a href="https://crypto-360.gitbook.io/defi-world/" target="_blank" rel="noreferrer" className="defi_btn">Whitepaper</a>
                                </div> */}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='imageBox text-center nftvideoSec'>
                                {/* <img src={featureanim} alt="featureanim" /> */}
                                <LazyLoad once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                    <ReactPlayer
                                        url={nftmain}
                                        width='100%'
                                        height='100%'
                                        muted
                                        loop
                                        playing={true}
                                        playsinline={true}
                                        autoPlay={true}
                                    />
                                </LazyLoad>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="nftStaking" id='howdefiSrl'>
                <div className="container buttonMainbanner">
                    <div className='titlebox titleboxNft'>
                        <h6>NFT Staking Platform Operates</h6>
                        <h2>How DEFI World's Staking Works?</h2>
                    </div>
                    <div className='descrNftStaking'>
                        <p>Users can seamlessly stake their NFTs on the DEFI World platform by connecting their wallets to the Polygon network. Staked NFTs will be locked up for a predetermined duration, during which users will earn rewards in the form of our native token. The distribution of rewards is executed automatically via smart contracts, ensuring an equitable and transparent process.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Advantages