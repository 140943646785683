import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './Pages/Layout';
import '@coreui/coreui/dist/css/coreui.min.css';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import loader from '../src/Assets/images/logo.gif'
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data) to showcase the loader
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Set a timeout of 2000 milliseconds (adjust as needed)
  }, []);

  return (
    <BrowserRouter>
      {loading ? (
        // Display the loader while loading is true
        <div className="loader-container">
          <span className="loader">
            <img src={loader} ></img>
          </span>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
          </Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
